
import { FacilityEntityModel, FacilityQueryModel } from '@/entity-model/facility-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';
import { FacilityType } from '@/model/enum';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/facility`;

export class FacilityService implements ICRUDQ<FacilityEntityModel, FacilityQueryModel> {
    public type: FacilityType;

    constructor(type: FacilityType) {
        this.type = type;
    }

    async create(model: FacilityEntityModel):Promise<FacilityEntityModel> {
        const url = `${URL_PATH}/${this.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityEntityModel> {
        const url = `${URL_PATH}/${this.type}/detail/${modelId}`;
        const res = await get(url);
        return new FacilityEntityModel(this.type).toModel(res);
    }

    async update(model: FacilityEntityModel):Promise<FacilityEntityModel> {
        const url = `${URL_PATH}/${this.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityEntityModel):Promise<FacilityEntityModel> {
        const url = `${URL_PATH}/${this.type}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/${this.type}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FacilityEntityModel(this.type).toModel(item));
        return res;
    }

    async getFacilityList(id: string):Promise<any> {
        const url = `${URL_PATH}/${this.type}/batchList/${id}`;
        const res = await get(url);
        return _.map(res, item => item = new FacilityEntityModel(this.type).toModel(item));
    }

    async getLinkedFacilityList(id: string):Promise<any> {
        const url = `${URL_PATH}/${this.type}/getLinkedList/${id}`;
        const res = await get(url);
        return _.map(res, item => item = new FacilityEntityModel(this.type).toModel(item));
    }

    async link(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/link`;
        return await post(url, { childId: id, parentId: parentId });
    }

    async unlink(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/unlink`;
        return await post(url, { childId: id, parentId: parentId });
    }

    async links(parentId: string, childId: Array<string>):Promise<any> {
        const url = `${URL_PATH}/${this.type}/batchLinkedSave`;
        return await post(url, { id: parentId, children: childId });
    }

    async batchCreateFacility(params: Array<any>):Promise<any> {
        const url = `${URL_PATH}/${this.type}/batchSave`;
        return await post(url, params);
    }

    async batchDelete(params: Array<any>):Promise<any> {
        const url = `${URL_PATH}/batchDelete`;
        return await post(url, params);
    }

    async batchUnlink(params: Array<any>):Promise<any> {
        const url = `${URL_PATH}/batchUnlink`;
        return await post(url, params);
    }

    async batchPosition(ids: Array<string>, positionId: string):Promise<any> {
        const url = `${URL_PATH}/batchChangePos`;
        return await post(url, { uuids: ids, spaceId: positionId });
    }

    async getAccessConfig():Promise<any> {
        const url = `${URL_PATH}/getAccessControlConfig`;
        return await get(url);
    }

    async setAccessConfig(id: string, configs: Array<number>):Promise<any> {
        const url = `${URL_PATH}/setAccessControlConfig`;
        return await post(url, { id, normalParkingRate: _.get(configs, '[0]'), emergentParkingRate: _.get(configs, '[1]') });
    }
}
